.react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    background-color: theme("colors.sideheadbar-color") !important;
    z-index: 40 !important;
  }
  
  .react-datepicker__header .react-datepicker__header--time {
    background-color: theme("colors.sideheadbar-color") !important;
  }
  
  .react-datepicker-time__header {
    color: theme("colors.text-color") !important;
  }
  
  .react-datepicker__time {
    background-color: theme("colors.sideheadbar-color") !important;
    color: theme("colors.text-color") !important;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: theme("colors.component-color") !important;
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
    border-top-right-radius: 1px !important;
    border-top-left-radius: 1px !important;
    color: theme("colors.text-color") !important;
  }
  
  .react-datepicker__day:hover {
    background-color: #ffb500 !important;
    color: black !important;
    border-radius: 100px;
  }
  
  .react-datepicker__day--in-range {
    background-color: #ffb500 !important;
    color: black !important;
    border-radius: 100px;
  }
  
  .react-datepicker__day--selecting-range-start {
    background-color: #ffb500 !important;
    color: black !important;
    border-radius: 100px;
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    color: black !important;
    background-color: #ffb500 !important;
    border-radius: 100px !important;
  }
  .react-datepicker-time__input .react-datepicker-time__input {
    background-color: theme("colors.background-color") !important;
    color: theme("colors.text-color") !important;
    fill: theme("colors.text-color") !important;
    padding: 2px !important;
    border: 2px solid theme("colors.border-color") !important;
    border-radius: 5px !important;
  }
  
  .react-datepicker-popper {
    border-radius: 0px !important;
    color: theme("colors.text-color") !important;
    border-radius: 2px;
    border-color: theme("colors.border-color");
  }
  
  .react-datepicker__close-icon::after {
    background-color: #ffb500;
    color: black;
    height: 20px !important;
    width: 20px !important;
    text-align: center !important;
    vertical-align: middle;
    justify-content: center;
    border-radius: 100px;
  }
  
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background-color: #ffb500 !important;
    color: black !important;
    font-weight: bold;
  }
  
  .react-datepicker__time-list-item:hover {
    background-color: #ffb500 !important;
    color: black !important;
  }
  
  .react-datepicker__time-container{
      border-left: 2px solid theme("colors.border-color") !important;
  }