.css-73fo9o-HEADER_CELL_CONTAINER_STYLE-HeaderCell {
    flex-direction: column;
}

.css-73fo9o-HEADER_CELL_CONTAINER_STYLE-HeaderCell>div {
    text-align: center !important;
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    align-self: center !important;
    justify-self: center !important;
    display: flex !important;
}


.css-mkjjv-CELL_CONTAINER_STYLE-Cell>div {
    text-align: center !important;
    align-items: center !important;
    justify-content: center !important;
    align-self: center !important;
    justify-self: center !important;
    display: flex !important;
}


.css-mkjjv-CELL_CONTAINER_STYLE-Cell {
    flex-direction: column;
    flex: 1;
    align-items: center !important;
    justify-content: center !important;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: #999;
}

::-webkit-scrollbar:vertical {
    width: 10px;
}

::-webkit-scrollbar:horizontal {
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: #666;
  
}

:-webkit-scrollbar-thumb:hover {
    background-color: #999;
}

::-webkit-scrollbar-track {
    background: transparent;
}

.css-3t2xfr-Table {
    line-height: 40px !important;
}

.css-8eb0m3-Body>div {
    border-radius: 20px !important;
}


.App {
    font-family: sans-serif;
    text-align: center;
}

.cutoff-box {
    border: 1px solid;
    padding: 5px;
    overflow: scroll;
    max-height: 300px;
    height: 300px;
    position: relative;
}

.select-placement {
    position: absolute;
    width: calc(100% - 10px);
    bottom: 25px;
}
