.react-datepicker-wrapper{
    width: 100%;
}
.react-datepicker {
    background-color: theme("colors.sideheadbar-color") !important;
    z-index: 40 !important;
}

.react-datepicker__header {
    text-align: center;
    background-color: theme("colors.component-color") !important;
    border-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative;
    border-top-right-radius: 1px !important;
    border-top-left-radius: 1px !important;
    color: theme("colors.text-color") !important;
}

.react-datepicker__day:hover {
    background-color: #FFB500 !important;
    color: black !important;
    border-radius: 100px;

}

.react-datepicker__day--in-range {
    background-color: #FFB500 !important;
    color: black !important;
    border-radius: 100px;

}

.react-datepicker__day--selecting-range-start {
    background-color: #FFB500 !important;
    color: black !important;
    border-radius: 100px;

}

.react-datepicker__day--keyboard-selected {
    background-color: #FFB500 !important;
    color: black !important;
    border-radius: 100px;

}

.react-datepicker__day--keyboard-hover {
    background-color: #FFB500 !important;
    color: black !important;
    border-radius: 100px;

}

.react-datepicker__day--in-selecting-range {
    background-color: #FFB500 !important;
    color: black !important;
    border-radius: 100px;
}

.react-datepicker {
    border-radius: 0px !important;
    color: theme("colors.text-color") !important;
    border-radius: 2px;
    border-color: theme("colors.border-color");
}

.react-datepicker__close-icon::after {
    background-color: #FFB500;
    color: black;
    height: 20px !important;
    width: 20px !important;
    text-align: center !important;
    vertical-align: middle;
    justify-content: center;
    border-radius: 100px;
}

.react-datepicker__current-month {
    color: theme("colors.text-color");
}

.react-datepicker__day-name {
    color: theme("colors.text-color");
}

.react-datepicker__day {
    color: theme("colors.text-color");
}