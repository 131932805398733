@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  :root {
    --background-color: 0deg 0% 9%;
    --component-color: 0deg 0% 12%;
    --border-color: 215deg 15% 22%;
    --text-color: 0deg 0% 100%;
    --sideheadbar-color: 0deg 0% 10%;
    --logo-color: 47deg 100% 50%;
    --table-head: 0deg 0% 90%;
  }

  @media (prefers-color-scheme: dark) {
    :root {
      --background-color: 0deg 0% 9%;
      --component-color: 0deg 0% 12%;
      --border-color: 215deg 15% 22%;
      --text-color: 0deg 0% 100%;
      --sideheadbar-color: 0deg 0% 10%;
      --logo-color: 47deg 100% 50%;
      --table-head: 0deg 0% 20%;
    }


  }


  :root[class="dark"] {
    --background-color: 0deg 0% 9%;
    --component-color: 0deg 0% 10%;
    --border-color: 215deg 15% 22%;
    --text-color: 0deg 0% 100%;
    --sideheadbar-color: 0deg 0% 12%;
    --logo-color: 47deg 100% 50%;
    --table-head: 0deg 0% 20%;
  }

  :root[class="light"] {
    --background-color: 0deg 0% 98%;
    --component-color: 0deg 0% 90%;
    --border-color: 212deg 6% 85%;
    --text-color: 0deg 0% 0%;
    --sideheadbar-color: 0deg 0% 95%;
    --logo-color: 47deg 100% 50%;
    --table-head: 0deg 0% 90%;
    
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  background-color: var(--background-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}





.rotating-border::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 4px solid transparent;
  border-top-color: #FFB500; 
  border-radius: 50%;
  animation: rotate-loading 3s infinite linear;
  box-sizing: border-box;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg); /* Tam tur atması için 360 derece */
  }
}


/* Tooltip Konteyneri */
.tooltip {
  position: relative;
  display: inline-block;
  margin: auto;
}

/* Tooltip Metni */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 160px;
  background-color: rgba(122, 122, 122, 0.8);
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; 
  left: 50%;
  margin-left: -80px; 
  opacity: 0;
  transition: opacity 0.3s;
}

/* Hover Durumunda Tooltip Görünür */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


@media (max-width: 768px) {
  .grid-dash {
    grid-template-columns: 1fr !important; /* Single column for mobile */
    gap: 1rem;
    height: auto !important;
  }

  .chart-container {
    width: 100%; /* Full width */
    height: 300px !important; /* Adjust height for mobile */
  }

  .dash-linechart {
    height: 300px !important;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .grid-dash {
    grid-template-columns: repeat(2, 1fr) !important; /* Two columns for medium screens */
    gap: 1.5rem;
    height: auto !important;
  }

  .chart-container {
    height: 300px !important; /* Adjust height for medium screens */
  }

  .dash-linechart {
    height: 300px !important;
  }
}


@media (min-width: 1200px) and (max-width: 1070px) {
  .grid-dash {
    grid-template-columns: repeat(3, 1fr) !important; /* Two columns for medium screens */
    gap: 1.5rem;
  }

  .chart-container {
    height: 300px !important; /* Adjust height for medium screens */
  }

  .dash-linechart {
    height: 300px !important;
  }
}

