
.react-select__menu-list div:hover{
    background-color: theme("colors.table-head") !important;
}

.react-select__menu-list div{
    background-color: theme("colors.sideheadbar-color") !important;
    color: theme("colors.text-color") !important;
}

.react-select__menu-list div[aria-selected="true"]{
    background-color: theme("colors.table-head") !important;
    color: theme("colors.text-color") !important;
}

.react-select__value-container{
    display: flex;
    align-self: stretch;
    background-color: theme("colors.sideheadbar-color") !important;
    color: theme("colors.text-color") !important;
    
}

.react-select-container .react-select__control {
    background-color: theme("colors.sideheadbar-color") !important;
    color: theme("colors.text-color") !important;
    border-color: theme("colors.border-color") !important;
    transition: none;
    height: 100% !important;
}

.react-select__menu{
    background-color: theme("colors.sideheadbar-color") !important;
    color: theme("colors.text-color") !important;
    border: 1px solid theme("colors.border-color") !important;
}

.react-select-container{
    height:100% !important
}